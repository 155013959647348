.js-productWorldMap {
  --pinW: 20px;
  --pinH: 28px;
}
.js-productWorldMap__mappinWrap {
  width: var(--pinW);
  height: var(--pinH);
  position: absolute;
  z-index: 1;
}
.js-productWorldMap__mappinWrap g {
  cursor: pointer;
}
.js-productWorldMap__mappinWrap.active {
  z-index: 2;
}
.js-productWorldMap__mappinWrap.--heat_exchanger g {
  fill: #06365a;
}
.js-productWorldMap__mappinWrap.--heat_exchanger.active g {
  fill: #0068B7;
}
.js-productWorldMap__mappinWrap.--heat_exchanger g:hover {
  fill: #0068B7;
}
.js-productWorldMap__mappinWrap.--vaporizer g {
  fill: #75170D;
}
.js-productWorldMap__mappinWrap.--vaporizer.active g {
  fill: #B23425;
}
.js-productWorldMap__mappinWrap.--vaporizer g:hover {
  fill: #B23425;
}
.js-productWorldMap__mappin {
  width: 100%;
  height: 100%;
}
.js-productWorldMap__mappin g {
  fill: #06365a;
}